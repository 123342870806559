import { createContext, PropsWithChildren } from 'react';

export const FeatureFlagPossibleState = {
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE',
} as const;

type FeatureFlagState = keyof typeof FeatureFlagPossibleState;

const featureFlagsMap = {
  FeatureFlag_BonusImpressions: import.meta.env.VITE_FF_BONUS_IMPRESSIONS,
} as const;

type FeatureFlagKey = keyof typeof featureFlagsMap;

export type FeatureFlags = Record<FeatureFlagKey, FeatureFlagState | undefined>;

export const FeatureFlagsContext = createContext<FeatureFlags | undefined>(undefined);

export function FeatureFlagsProvider({ children }: PropsWithChildren) {
  return <FeatureFlagsContext.Provider value={featureFlagsMap}>{children}</FeatureFlagsContext.Provider>;
}
