import { InteractionStatus } from '@azure/msal-browser';
import { AccountInfo, InteractionRequiredAuthError } from '@azure/msal-common';
import { useMsal } from '@azure/msal-react';
import { captureException, captureMessage } from '@sentry/react';
import { jwtDecode } from 'jwt-decode';
import { useRef } from 'react';

import { authRequest, authRequestForSilentAndPopupFlows } from '@shared/providers/AzureAuthProvider/authConfig.ts';

export interface UseAuth {
  getPersonalInfo: () => Promise<string | null>;
  getRoleId: () => Promise<string | null>;
  logout: () => void;
  getUserId: () => Promise<string | null>;
}

interface RequestToken {
  account?: AccountInfo;
  scopes: string[];
  [key: string]: unknown;
}

export const EXTERNAL_USER_ROLE_ID = 'externalUserRoleId';

export function useAuth(): UseAuth {
  const storedAccount = useRef<AccountInfo>();
  const { instance, accounts, inProgress } = useMsal();

  const accountsFound = accounts && accounts.length > 0;

  if (accountsFound && !storedAccount.current) {
    storedAccount.current = accounts[0];
  }

  const logout = async () => {
    await instance.logoutRedirect().catch((error) => {
      captureMessage('Failed to logout user.');
      captureException(error, { level: 'error' });
    });
  };

  async function fallbackProcedure(error: unknown, requestToken: RequestToken) {
    if (error instanceof InteractionRequiredAuthError && inProgress === InteractionStatus.None) {
      try {
        await instance.acquireTokenRedirect(requestToken);
      } catch (redirectError) {
        captureMessage('Failed to acquire token redirect. Attempting to logout...');
        captureException(redirectError, { level: 'error' });

        await logout();
      }
    } else {
      captureMessage('Failed to acquire token silently. Attempting to logout...');
      captureException(error, { level: 'error' });

      await logout();
    }
  }

  const getPersonalInfo = async () => {
    const accessTokenRequest = { ...authRequest, account: storedAccount.current };
    const accessTokenSilentRequest = { ...authRequestForSilentAndPopupFlows, account: storedAccount.current };

    try {
      const {
        account: { name },
      } = await instance.acquireTokenSilent(accessTokenSilentRequest);

      return name || '';
    } catch (error) {
      await fallbackProcedure(error, accessTokenRequest);
    }
    return null;
  };

  const getUserId = async (): Promise<string | null> => {
    const accessTokenRequest = { ...authRequest, account: storedAccount.current };
    const accessTokenSilentRequest = { ...authRequestForSilentAndPopupFlows, account: storedAccount.current };

    try {
      const { idTokenClaims } = await instance.acquireTokenSilent(accessTokenSilentRequest);

      if (!idTokenClaims) {
        return null;
      }

      return 'oid' in idTokenClaims && typeof idTokenClaims.oid === 'string' ? idTokenClaims.oid : null;
    } catch (error) {
      await fallbackProcedure(error, accessTokenRequest);
    }

    return null;
  };

  const getRoleId = async (): Promise<string | null> => {
    const accessTokenRequest = { ...authRequest, account: storedAccount.current };
    const accessTokenSilentRequest = { ...authRequestForSilentAndPopupFlows, account: storedAccount.current };

    try {
      const { idTokenClaims } = await instance.acquireTokenSilent(accessTokenSilentRequest);

      if (!idTokenClaims) {
        return null;
      }

      if ('idp_id_token' in idTokenClaims && typeof idTokenClaims.idp_id_token === 'string') {
        const decodedIdpIdToken = jwtDecode(idTokenClaims.idp_id_token);

        const roleId =
          'groups' in decodedIdpIdToken && Array.isArray(decodedIdpIdToken.groups) ? decodedIdpIdToken.groups[0] : null;

        return roleId;
      }

      return EXTERNAL_USER_ROLE_ID;
    } catch (error) {
      await fallbackProcedure(error, accessTokenRequest);
    }

    return null;
  };

  return { logout, getRoleId, getPersonalInfo, getUserId };
}
