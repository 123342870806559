import { BrowserAuthErrorCodes } from '@azure/msal-browser';
import { AuthError } from '@azure/msal-common';
import axios, { AxiosError, AxiosHeaders, AxiosInstance, AxiosRequestConfig, RawAxiosRequestHeaders } from 'axios';

import { authRequestForSilentAndPopupFlows, msalInstance } from '@shared/providers/AzureAuthProvider/authConfig';
import { routePaths } from '@shared/router/routePaths';

const REQUEST_CANCELED = 'ERR_CANCELED';

const MSAL_ERROR_CODES = [BrowserAuthErrorCodes.monitorWindowTimeout, BrowserAuthErrorCodes.interactionInProgress];

const handleServerError = () => {
  return (window.location.href = routePaths.serverDown);
};

const shouldRedirectWithoutToast = (error: AxiosError) => {
  return (
    !error.response?.status ||
    (error.response.data &&
      typeof error.response.data === 'object' &&
      (('showUser' in error.response.data && error.response.data.showUser === false) ||
        !('showUser' in error.response.data)))
  );
};

const msalRedirectError = (error: AuthError) => MSAL_ERROR_CODES.includes(error.errorCode);

class AxiosConfig {
  private _baseUrl = import.meta.env.VITE_API_URL;
  private _headerConfigs?: RawAxiosRequestHeaders | AxiosHeaders;
  private _configs: AxiosRequestConfig = {
    baseURL: this._baseUrl,
    timeout: 60000,
    headers: this._headerConfigs,
  };
  private _instance?: AxiosInstance;

  public get headerConfigs() {
    return this._headerConfigs;
  }

  public set headerConfigs(hConfigs) {
    this._headerConfigs = hConfigs;
  }

  public get axiosInstance() {
    return this._instance;
  }

  constructor(headers?: RawAxiosRequestHeaders | AxiosHeaders) {
    this._instance = axios.create(this._configs);
    this._headerConfigs = Object.assign({}, this._configs.headers, headers);

    this._instance?.interceptors.request.use(async (config) => {
      if (process.env.NODE_ENV === 'test') {
        return config;
      }

      const account = msalInstance.getActiveAccount();

      if (!account) {
        throw Error('No active account! Verify a user has been signed in and setActiveAccount has been called.');
      }

      const response = await msalInstance.acquireTokenSilent({
        ...authRequestForSilentAndPopupFlows,
        account: account,
      });

      const accessToken = response.accessToken;

      if (config.headers) {
        config.headers.Authorization = `Bearer ${accessToken}`;
      }

      return config;
    });

    this._instance?.interceptors.response.use(
      (response) => {
        return response;
      },
      (error: AxiosError | AuthError) => {
        const isAxiosError = error instanceof AxiosError;
        const isMsalError = error instanceof AuthError;

        if (isMsalError && msalRedirectError(error)) {
          return Promise.reject(error);
        }

        if (isAxiosError && error.code === REQUEST_CANCELED) {
          return Promise.reject(error);
        }

        if (isAxiosError && shouldRedirectWithoutToast(error)) {
          return handleServerError();
        }

        if (isAxiosError && error.request && error.response) {
          if (
            error.request.responseType === 'blob' &&
            error.response.data instanceof Blob &&
            error.response.data.type &&
            error.response.data.type.toLowerCase().indexOf('json') !== -1
          ) {
            return new Promise((_resolve, reject) => {
              const reader = new FileReader();
              reader.onload = () => {
                if (!error.response || !reader.result) {
                  return;
                }

                error.response.data = JSON.parse(reader.result as string);
                reject(error);
              };

              reader.onerror = () => {
                reject(error);
              };

              reader.readAsText(error.response?.data as Blob);
            });
          }
        }

        return Promise.reject(error);
      },
    );
  }
}

export default AxiosConfig;
