import { BrowserCacheLocation, Configuration, LogLevel, PublicClientApplication } from '@azure/msal-browser';

const msalConfig: Configuration = {
  auth: {
    clientId: import.meta.env.VITE_AD_SPA_CLIENT_ID,
    authority: import.meta.env.VITE_AD_AUTHORITY,
    redirectUri: import.meta.env.VITE_AD_REDIRECT_URI,
    knownAuthorities: [new URL(import.meta.env.VITE_AD_AUTHORITY).hostname],
  },
  cache: {
    cacheLocation: BrowserCacheLocation.LocalStorage,
    storeAuthStateInCookie: true,
  },
  system: {
    loggerOptions: {
      logLevel: LogLevel.Warning,
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Info:
            console.info(message);
            return;
          case LogLevel.Verbose:
            console.debug(message);
            return;
          case LogLevel.Warning:
            console.warn(message);
            return;
          default:
            return;
        }
      },
    },
  },
};

export const authRequest = {
  scopes: ['openid', import.meta.env.VITE_AD_API_SCOPE],
};

export const authRequestForSilentAndPopupFlows = {
  ...authRequest,
  redirectUri: `${import.meta.env.VITE_AD_REDIRECT_URI}/blank.html`,
};

export const msalInstance = new PublicClientApplication(msalConfig);
